<template>
  <div class="bg-gradient flex h-full flex-col">
    <AppNavbar />

    <main class="container mt-6 grow pb-5 max-md:px-5 lg:mt-[60px]">
      <slot />
    </main>

    <AppFooter />
  </div>
</template>
